import React from 'react';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import StoreIcon from '@material-ui/icons/Store';
import DateView from '../../components/DateView';
import Label from '../../components/Label';
import BoolLabel from '../../components/Label/BoolLabel';
import { ICustomer } from '../../api/customers';
import { IColumn } from '../../hooks/useColumns';
import useMobileStyles from '../../hooks/useMobileStyles';
import Tablecell from '../../components/TableCell/TableCell';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    cursor: 'pointer',
  },
  id: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  store: {
    width: '15px',
    height: '24px',
    marginRight: theme.spacing(1),
    '& svg': {
      width: '15px'
    }
  },
  idCustomer: {
    width: 150,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      width: '100%',
    },
  }
}));

interface ICustomersListProps {
  items: ICustomer[];
  columns: IColumn[];
  onRowClick: any;
}

export default function CustomersList({ items, onRowClick, columns }: ICustomersListProps) {
  const classes = useStyles();
  const mobileClasses = useMobileStyles();

  const content: { [key: string]: (item: ICustomer, key: number) => void } = {
    id: (i, k) => (
      <TableCell key={k} align="center" className={classes.idCustomer}>
        <div className={classes.id}>
          <div className={classes.store}>
            {i.authorities?.includes('ROLE_MERCHANT') && <StoreIcon />}
          </div>
          {i.id}
        </div>
      </TableCell>
    ),
    contact: (i, k) => <Tablecell title="Email/Phone" key={k}>{i.email || i.phone}</Tablecell>,
    level: (i, k) => <Tablecell title="KYC Level" key={k}>{i.level}</Tablecell>,
    confirmed: (i, k) => (
      <Tablecell title="Confirmed" key={k}>
        <BoolLabel value={i.email ? i.emailConfirmed : i.phoneConfirmed} size='small' />
      </Tablecell>
    ),
    kyc: (i, k) => <Tablecell title="KYC started" key={k}><BoolLabel value={!!i.kycApplication} size='small' /></Tablecell>,
    verified: (i, k) => <Tablecell title="KYC passed" key={k}><BoolLabel value={i.fullVerified} size='small' /></Tablecell>,
    created: (i, k) => <Tablecell title="Created at" key={k}><DateView value={i.createdAt} /></Tablecell>,
    legalEntity: (i, k) => {
      const text = i.termsLegalEntities?.length
        ? i.termsLegalEntities.join(', ')
        : i.legalEntity ?? '';

      return (
        <Tablecell title="Legal Entity" key={k}>
          {text}
        </Tablecell>
      );
    },
    businessName: (i, k) => <Tablecell title="Business Name" key={k}>{i?.merchantNaming?.businessName}</Tablecell>,
    legalName: (i, k) => <Tablecell title="Legal Name" key={k}>{i?.merchantNaming?.legalName}</Tablecell>,
    legalMerchantName: (i, k) => <Tablecell title="Legal Merchant Name" key={k}>{i?.merchantNaming?.legalMerchantName}</Tablecell>,
    merchantName: (i, k) => <Tablecell title="Merchant Name" key={k}>{i?.merchantNaming?.merchantName}</Tablecell>,
    accountType: (i, k) => <Tablecell title="Account type" key={k}>{i?.merchantNaming?.accountType}</Tablecell>,
    ccwEnabled: (i, k) => (
      <Tablecell title="Ccw Enabled" key={k}>
        <BoolLabel value={i.ccwEnabled} size='small' />
      </Tablecell>
    ),
    subAccountSid: (i, k) => <Tablecell title="Sub account SID" key={k}>{i?.subAccountSid}</Tablecell>
  }

  return (
    <Table size="small">
      <TableHead className={mobileClasses.tableHead}>
        <TableRow>
          {columns.map((c) => c.component || <TableCell key={c.id}>{c.name}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((i) => (
          <TableRow key={i.id} className={clsx(classes.row, mobileClasses.row, mobileClasses.lastChild)} onClick={() => onRowClick(i.id)} hover>
            {columns.map((c, index) => content[c.id](i, index))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
