import React from 'react';

import { Form } from 'react-final-form';

import { useSelector } from 'react-redux';

import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { IKycCountry } from '../../../../api/kyc';
import useFormStyles from '../../../../hooks/useFormStyles';
import { ICustomer, useBillingAddress } from '../../../../api/customers';
import { getBillingAddressFormInitialValues } from '../../../../helpers';
import { composeValidators, validateDate, required } from '../../../../validators';
import { DateField, Input, ProtectionInput, Select } from '../../../../components/Form';

interface IBillingAddressFormProps {
  customer: ICustomer;
  onSave: (id: string) => void;
}

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2)
  }
}));

export default function BillingAddressForm({ customer, onSave }: IBillingAddressFormProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const countries = useSelector((store: any) => store.appData.countries.data);

  const [save, saving] = useBillingAddress((res: any) => {
    if (res.success) {
      onSave(customer.id);
    }
  });

  return (
    <Form
      onSubmit={save}
      initialValues={getBillingAddressFormInitialValues(customer)}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}  className={clsx(formClasses.root, classes.form)}>
          <Typography variant="body1" color="primary" align="left">Billing address</Typography>
          <div className={formClasses.row}>
            <Select
              fullWidth
              margin="normal"
              validate={required} 
              name="country"
              label="Country"
              options={countries?.map((country: IKycCountry) => ({ value: country.code, label: country.name }))}
            />
            <Input
              type="search"
              autoComplete="xyz123"
              name="city"
              label="City"
              validate={required}
            />
          </div>
          <div className={formClasses.row}>
            <Input
              type="search"
              autoComplete="xyz123"
              name="zipCode"
              label="Zip Code"
              validate={required} 
            />
            <Input
              type="search"
              autoComplete="xyz123"
              name="middleName"
              label="Middle name" 
            />
          </div>
          <div className={formClasses.row}>
            <Input
              type="search"
              autoComplete="xyz123"
              name="firstName"
              label="First name"
              validate={required} 
            />
            <Input
              type="search"
              autoComplete="xyz123"
              name="lastName"
              label="Last name"
              validate={required} 
            />
          </div>
          <div className={formClasses.row}>
            <Input
              type="search"
              autoComplete="xyz123"
              name="address"
              label="Address"
              validate={required} 
            />
          </div>
          <div className={formClasses.row}>
            <DateField
              margin
              fullWidth
              name="dateOfBirth"
              label="Date of birth"
              validate={composeValidators(required, validateDate)}
            />
            <Select
              fullWidth
              margin="normal"
              validate={required} 
              name="placeOfBirth"
              label="Place of birth"
              options={countries?.map((country: IKycCountry) => ({ value: country.code, label: country.name }))}
            />
          </div>
          <div className={formClasses.row}>
            <ProtectionInput />
          </div>
          <div className={formClasses.actions}>
            <Button
              type="submit"
              disabled={saving}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    />
  );
}
