export const merchantsForCopying = {
  prod: [
    {
      label: 'UAB noKYC',
      value: '157475334418801',
    },
    {
      label: 'UAB KYC',
      value: '157475485099995',
    },
    {
      label: 'CV noKYC',
      value: '157475515189309',
    },
    {
      label: 'CV KYC',
      value: '157475551335634',
    },
  ],
  stage: [
    {
      label: 'UAB noKYC',
      value: '158807941205787',
    },
    {
      label: 'UAB KYC',
      value: '158807927014167',
    },
    {
      label: 'CV noKYC',
      value: '136228814820627',
    },
    {
      label: 'CV KYC',
      value: '135621905251975',
    },
  ],
  dev: [
    {
      label: 'UAB noKYC',
      value: '158807765509273',
    },
    {
      label: 'UAB KYC',
      value: '158807753088149',
    },
    {
      label: 'CV noKYC',
      value: '158807742140561',
    },
    {
      label: 'CV KYC',
      value: '147726152670548',
    },
  ],
};