import React, { FC } from 'react';
import { CurrencyType } from '../../../../api/currency';
import { ISettlement, SettlementStatus, useSettlementAdditionalFeeUpdate } from '../../../../api/settlements';
import { Form } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import Big from 'big.js';
import { Input, ProtectionInput } from '../../../../components/Form';
import PopupLink from '../../../../components/UI/PopupLink';
import useFormStyles from '../../../../hooks/useFormStyles';
import { mustBeNumber } from '../../../../validators';
import { IMappedSettlementOperation } from '../SettlementInfo';
import { IMerchantSettlementFees } from '../../../../types';

export interface ISettlementFees {
	settlement: ISettlement;
	operations: IMappedSettlementOperation[];
	type: CurrencyType;
	onAdditionalFeeUpdate(): void;
	customerFeesMap?: Record<string, IMerchantSettlementFees>;
}

const calcFee = (amount?: number | null, feePercent?: number | null): string => {
	return amount != null && feePercent != null
		? Big(amount).mul(feePercent).round(2, Big.roundUp).toString()
		: '-';
};

export const SettlementFees: FC<ISettlementFees> = ({
	settlement: { settlementFees, calculatedFees, id, status, customerId },
	operations,
	type,
	onAdditionalFeeUpdate,
	customerFeesMap = {}
	}) => {
	const formClasses = useFormStyles();
	const [setAdditionalFee, savingAdditionalFee] = useSettlementAdditionalFeeUpdate((res: any) => {
		if (res.success) onAdditionalFeeUpdate();
	});

	const hasCalculatedFees = !!calculatedFees;

	const getFees = (o: IMappedSettlementOperation) => {
		const feesForSettlement = settlementFees && settlementFees[customerId] || {};
		const merchantSettlementFees = customerFeesMap[o.customerId] || {};

		let fiatFee = '-';
		let cryptoFee = '-';
		let fxFee = '-';
		let addFee = '-';

		if (hasCalculatedFees) {
			const customer = calculatedFees?.customerFees?.find((c) => c.customerId.toString() === o.customerId);
			const currencyFee = customer?.currencyFeesList?.find((c) => c.currencyCode === o.currency);
			const feeDetails = currencyFee?.feeDetails;

			fiatFee = feeDetails?.fiatSettlementFee?.toString() ?? '-';
			cryptoFee = feeDetails?.cryptoSettlementFee?.toString() ?? '-';
			fxFee = feeDetails?.fxSettlementFee?.toString() ?? '-';
			addFee = feeDetails?.additionalSettlementFee?.toString() ?? '-';
		} else {
			const additionalSetFee = feesForSettlement?.additionalSettlementFee;
			fiatFee = type === CurrencyType.FIAT ? calcFee(o.amount, merchantSettlementFees.fiatSettlementFee) : '-';
			cryptoFee = type === CurrencyType.CRYPTO
				? calcFee(o.amount, merchantSettlementFees.cryptoSettlementFee) : '-';
			fxFee = o.currency !== 'EUR' ? calcFee(o.amount, merchantSettlementFees.fxSettlementFee) : '-';
			addFee = additionalSetFee ? calcFee(o.amount, additionalSetFee) : '-';
		}

		return { fiatFee, cryptoFee, fxFee, addFee };
	};

	return (
		<>
			<Typography variant="body1" component="div" color="primary">
				Settlement Fees
			</Typography>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Customer ID</TableCell>
						<TableCell align="center">Currency</TableCell>
						<TableCell align="center">Requested Amount</TableCell>
						<TableCell align="center">Fiat Settlement Fee</TableCell>
						<TableCell align="center">Crypto Settlement Fee</TableCell>
						<TableCell align="center">FX Settlement Fee</TableCell>
						<TableCell align="center">Additional Settlement Fee</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{operations.map((o, index) => {
						const { fiatFee, cryptoFee, fxFee, addFee } = getFees(o);
						return (
							<TableRow key={index}>
								<TableCell>
									<PopupLink popup="customer" id={o.customerId} baseUrl={'/customers'}>
										{o.customerId}
									</PopupLink>
								</TableCell>
								<TableCell align="center">{o.currency}</TableCell>
								<TableCell align="center">{o.amount}</TableCell>
								<TableCell align="center">{fiatFee}</TableCell>
								<TableCell align="center">{cryptoFee}</TableCell>
								<TableCell align="center">{fxFee}</TableCell>
								<TableCell align="center">{addFee}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			{status === SettlementStatus.REQUESTED && (
				<Form
					initialValues={{
						additionalSettlementFee:
							settlementFees ? settlementFees[customerId].additionalSettlementFee : 0,
						'2fa': '',
					}}
					onSubmit={(values) => setAdditionalFee({ ...values, settlementId: id })}
					render={({ valid, handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<div className={formClasses.row}>
								<Input name="additionalSettlementFee" label="Additional Fee" validate={mustBeNumber} />
								<ProtectionInput />
								<Button
									variant="contained"
									type="submit"
									className="v-center"
									style={{ flex: '0 0 100px' }}
									disabled={!valid || savingAdditionalFee}
								>
									Save
								</Button>
							</div>
						</form>
					)}
				/>
			)}
		</>
	);
};
