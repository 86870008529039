import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';

import { Form } from 'react-final-form';

import clsx from 'clsx';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import DetailsPopup from '../../../components/Layout/DetailsPopup';
import { DataRow } from '../../../components/DataView';
import { useBlockchainReinitTx, useBlockchainTxOne } from '../../../api/blockchain';
import Extra from '../../../components/UI/ExtraData';
import PopupLink from '../../../components/UI/PopupLink';
import BlockchainLink from '../../../components/BlockchainLink';
import BlockchainTxStatusLabel from '../../../components/Label/BlockchainTxStatusLabel';
import { splitAddress } from '../../../helpers/addressHelper';
import DateView from '../../../components/DateView';
import { ProtectionInput } from '../../../components/Form';
import ShortString from '../../../components/UI/ShortString';
import EmptyList from '../../../components/EmptyList';
import BoolLabel from "../../../components/Label/BoolLabel";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    marginBottom: 15,
  },
  currency: {
    borderRadius: 5,
    backgroundColor: "#42a5f5",
    padding: "2px 10px",
  },
  sendConfirmation: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    backgroundColor: "#424242",
    borderRadius: "6px",
    maxWidth: 600
  },
  content: {
    maxWidth: 400,
    [theme.breakpoints.down(700)]: {
      overflowWrap: 'anywhere'
    },
  },
  text: {
    [theme.breakpoints.down(700)]: {
      overflowWrap: 'anywhere'
    },
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  }
}));

interface IBlockchainTxDetailsProps {
  id: string;
  onClose: () => void;
  updateList: () => void;
}
export function BlockchainTxDetails({ onClose, id, updateList }: IBlockchainTxDetailsProps) {
  const classes = useStyles();
  const history = useHistory();
  const [{ data: blockchainTx, error }, loading, load] = useBlockchainTxOne();
  const [address] = splitAddress(blockchainTx?.address);
  const [openDialog, setOpenDialog] = useState(false);

  const [reinitTx, sendingReinitTx] = useBlockchainReinitTx((res: any) => {
    setOpenDialog(false);
    history.push('/blockchain');
    updateList();
  });

  useEffect(()=> {
    load(id);
  }, [id]);

  return (
    <DetailsPopup onClose={onClose} loading={loading}>
      {blockchainTx && Object.keys(blockchainTx).length && 
        <>
          <Typography className={classes.title} color="primary" variant="h6">
            ID {blockchainTx.id}
          </Typography>
          <DataRow label="Order ID">
            <PopupLink popup="order" id={blockchainTx.orderId}>
              {blockchainTx.orderId}
            </PopupLink>
          </DataRow>
          <DataRow label="Address">
            <BlockchainLink
              charsLimit={50}
              value={address}
              type="address"
              currency={blockchainTx.currency}
            />
          </DataRow>
          <DataRow label="Customer Email">{blockchainTx.customerEmail}</DataRow>
          <DataRow label="Status">
            <BlockchainTxStatusLabel status={blockchainTx.status} />
          </DataRow>
          {blockchainTx.status === 'ERROR' && (
            <DataRow label="Error">
              <Button
                variant="contained"
                size="small"
                onClick={() => setOpenDialog(true)}
              >
                Resubmit
              </Button>
            </DataRow>
          )}
          {blockchainTx?.error && (
            <>
              <DataRow label="Error Message" copy={blockchainTx.error}>
                <ShortString chars={50} text={blockchainTx.error} />
              </DataRow>
              <DataRow label="Error Message">
                <Typography className={classes.content}>
                  <Extra data={blockchainTx.error} />
                </Typography>
              </DataRow>
            </>
          )}
          <DataRow label="Amount">{blockchainTx.amount}</DataRow>
          <DataRow label="Currency">
            <Typography className={classes.currency}>
              {blockchainTx.currency}
            </Typography>
          </DataRow>
          <DataRow label="Fee">{blockchainTx.fee}</DataRow>
          <DataRow label="Provider">{blockchainTx.provider}</DataRow>
          <DataRow label="TX ID">
            <BlockchainLink
              charsLimit={50}
              value={blockchainTx.txId}
              type="tx"
              provider={blockchainTx.provider}
              currency={blockchainTx.currency}
            />
          </DataRow>
          <DataRow label="Created at">
            <DateView value={blockchainTx.createdAt} />
          </DataRow>
          <DataRow label="Updated at">
            <DateView value={blockchainTx.updatedAt} />
          </DataRow>
          {blockchainTx.request && (
            <DataRow label="Request">
              <Typography className={classes.text}>
                <Extra data={blockchainTx.request} />
              </Typography>
            </DataRow>
          )}
          {blockchainTx.response && (
            <DataRow label="Response">
              <Typography className={clsx(classes.content)}>
                <Extra data={blockchainTx.response} />
              </Typography>
            </DataRow>
          )}
          <DataRow label="CCW">
            <BoolLabel value={blockchainTx.ccw} size='small' />
          </DataRow>
        </>}
      <>
        {openDialog && 
          <div
            className={classes.modal}
            aria-labelledby="alert-modal-title"
            aria-describedby="alert-modal-description">
              <div className={classes.sendConfirmation}>
                <Form
                  onSubmit={(values) => {
                    reinitTx({ ...values, transactionId: blockchainTx.id });
                  }}
                  initialValues={{ "2fa": "" }}
                  render={({ handleSubmit, values, invalid }) => (
                    <form onSubmit={handleSubmit}>
                      <DialogContent>
                        <DialogContentText id="alert-modal-description">
                          Are you sure that you want to reinitiate blockchain tx
                          with ID: {blockchainTx.id}
                          <ProtectionInput />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setOpenDialog(false)} size="small">
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          color="secondary"
                          disabled={invalid}
                        >
                          Confirm
                        </Button>
                      </DialogActions>
                    </form>
                  )}
                />
              </div>
          </div>}
      </>
      {(!blockchainTx || !Object.keys(blockchainTx).length || error) && 
      <EmptyList loading={loading} error={error} text="No data to display" width={500} />}
    </DetailsPopup>
  );
}
