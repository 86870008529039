import { Button, Link, makeStyles, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useMemo, useState } from 'react';
import { CurrencyType, ICurrency } from '../../../api/currency';
import { ISettlement, SettlementStatus, useCustomerSettlementFees } from '../../../api/settlements';

import TextCopy from '../../../components/TextCopy';
import { DataRow } from '../../../components/DataView';
import DateView from '../../../components/DateView';
import Label from '../../../components/Label';
import PopupLink from '../../../components/UI/PopupLink';
import ShortString from '../../../components/UI/ShortString';
import { ISettlementFees, MerchantsFees, SettlementFees } from './components';
import { getSettlementStatusType, sumOperationsByCurrency } from '../../../helpers/settlementHelper';
import { IMerchantSettlementFees } from '../../../types';

export interface IMappedSettlementOperation {
  customerId: string
  amount: number
  currency: string
}

interface ISettlementInfo extends Pick<ISettlementFees, 'onAdditionalFeeUpdate'> {
  settlement: ISettlement;
  currencies: ICurrency[];
  setOpenDialog: (open: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2.5),
  },
}));

function SettlementInfo({ settlement, setOpenDialog, currencies, ...feesProps }: ISettlementInfo) {
  const [settlementType, setSettlementType] = useState<CurrencyType | undefined>(CurrencyType.FIAT);
  const classes = useStyles();

  const mappedSettlementOperations: IMappedSettlementOperation[] = [];

  if (Object.keys(settlement.operations).length > 0) {
    Object.entries(settlement.operations).forEach(([custId, requestInfo]) => {
      Object.entries(requestInfo as Record<string, number>).forEach(([currency, amount]) => {
        mappedSettlementOperations.push({ customerId: custId, currency, amount });
      });
    });
  }

  const operationsSummedByCurrency  = sumOperationsByCurrency(settlement.operations);

  useEffect(() => {
    if (settlement.status === SettlementStatus.SETTLED) {
      const currency = currencies.find((c) => c.currency === settlement.settlementCurrency);
      setSettlementType(currency?.type);
    }
  }, [settlement.status]);

  const uniqueCustomerIds = useMemo(() => {
    const ids = mappedSettlementOperations.map((o) => Number(o.customerId));
    return Array.from(new Set(ids));
  }, [mappedSettlementOperations]);

  const [fetchedData, loading, loadCustomerFees] = useCustomerSettlementFees();
  const { items: fetchedCustomerFees } = fetchedData;
  const [hasLoadedCustomerFees, setHasLoadedCustomerFees] = useState(false);

  useEffect(() => {
    if (uniqueCustomerIds.length > 0 && !hasLoadedCustomerFees) {
      loadCustomerFees({ customerIds: uniqueCustomerIds });
      setHasLoadedCustomerFees(true);
    }
  }, [uniqueCustomerIds, loadCustomerFees, hasLoadedCustomerFees]);

  const customerFeesMap = useMemo(() => {
    const map: Record<string, IMerchantSettlementFees> = {};
    if (Array.isArray(fetchedCustomerFees)) {
      fetchedCustomerFees.forEach(item => {
        map[item.customerId.toString()] = item.settlementFees;
      });
    }
    return map;
  }, [fetchedCustomerFees]);

  return (
    <>
      <Typography
        color="primary"
        variant="h6"
        className={classes.title}
      >
        Settlement ID {settlement.id}
        <TextCopy text={String(settlement.id)} />
      </Typography>
      <DataRow label="Status">
        <Label label={settlement.status} type={getSettlementStatusType(settlement.status)} size="small" />
      </DataRow>
      <DataRow label="Merchant ID">
        <PopupLink popup="customer" id={settlement.customerId} baseUrl={'/customers'}>
          {settlement.customerId}
        </PopupLink>
        <TextCopy text={String(settlement.customerId)} />
      </DataRow>
      <DataRow label="Legal entity">
        {settlement?.legalEntity ?? ''}
      </DataRow>
      <DataRow label="Requested amount">{settlement.requestedEurAmount} EUR</DataRow>
      <DataRow label="Settled amount">
        {settlement?.settlementAmount ? `${settlement.settlementAmount} ${settlement.settlementCurrency}` : '-'}
      </DataRow>
      <DataRow label="Created"><DateView value={settlement.createdAt} /></DataRow>
      <DataRow label="Updated"><DateView value={settlement.updatedAt} /></DataRow>
      {settlement.status === SettlementStatus.SETTLED && settlement?.blockchainTxId && (
        <DataRow label="Tx Hash">
          <Link href={settlement.blockchainTxId} target="_blank" rel="noopener noreferrer">
            <ShortString chars={50} text={settlement.blockchainTxId} />
          </Link>
        </DataRow>
      )}
      {settlement.status === SettlementStatus.REQUESTED && (
        <>
          <DataRow label="Сhange status">
            <Button
              variant="contained"
              size="small"
              onClick={() => setOpenDialog(true)}
            >
              Сhange
            </Button>
          </DataRow>
          <TextField
              select
              value={settlementType}
              variant="outlined"
              label="Settlement Type"
              style={{ width: 300 }}
              margin="normal"
              // @ts-ignore
              onChange={(e) => setSettlementType(e.target.value)}
            >
              <MenuItem>&nbsp;</MenuItem>
              <MenuItem value={CurrencyType.CRYPTO}>Crypto</MenuItem>
              <MenuItem value={CurrencyType.FIAT}>Fiat</MenuItem>
          </TextField>
        </>
      )}

      <Typography variant="body1" component="div" color="primary">Requested amounts</Typography>
      {operationsSummedByCurrency.length > 0 &&
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Amount</TableCell>
            <TableCell>Currency</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {operationsSummedByCurrency.map((row) =>
            (
              <TableRow key={row.currency}>
                <TableCell>{row.amount.toFixed(2)}</TableCell>
                <TableCell>{row.currency}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
      }
      <br />
      {settlementType && customerFeesMap && (
        <SettlementFees
          settlement={settlement}
          operations={mappedSettlementOperations}
          type={settlementType}
          customerFeesMap={customerFeesMap}
          {...feesProps}
        />
      )}
      <br />
      {customerFeesMap && <MerchantsFees fees={customerFeesMap} />}
    </>
  );
}
export default SettlementInfo;
