import Api, { useFetchApi, useUpdateApi } from '.';

export interface IOBIReport {
  id: string
  email: string
  createdAt: number
  updatedAt: number
  legalEntity: string
  businessName: string
  executedOrdersSum: number
  executedOrdersCount: number
  enabled: boolean
}

export function useOBIReportsList() {
   return useFetchApi(
     async (query: any) => Api.post('/report/list', { ...query }),
     { items: [] }
   );
}

export function useOBIReportCreate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/report/create', data),
    cb,
    'Report has been successfully created'
  );
}

export function useOBIReportUpdate(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/report/update', data),
    cb,
    'Report has been successfully updated'
  );
}