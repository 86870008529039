import Api, { useFetchApi, useUpdateApi } from './index';

export enum TransactionRequirement{
  WITHDRAWAL,
  DEPOSIT,
  BOTH
}

export interface IRequiredApmField {
  id: string;
  validation?: string;
  transactionRequirement?: TransactionRequirement;
  checkFieldFromInternalStorage?: boolean;
  localizedValidation?: Record<string, string>;
  localizedName?: Record<string, string>;
}

export interface IBank {
  name: string;
  logo: string;
}

export interface IFormBank extends IBank {
  id: string
}

export interface IApmSettings {
  logo?: string;
  localizedName?: { en: string, ru: string };
  metadata: IApmMetadata;
  fields?: IRequiredApmField;
  checkOnlyApmKycLimit?: boolean;
}

export interface IFormApmSettings {
  logo?: string;
  localizedName?: { en: string, ru: string };
  metadata: IFormBank[] | undefined;
}

export type IApmMetadata = Record<string, IBank>

export interface IApm {
  id: number;
  name: string;
  externalId: string;
  provider: string;
  createdAt: number;
  updatedAt: number;
  enabled: boolean;
  flow: string;
  countries?: string[];
  settings: IApmSettings;
  currencies: string[];
  excludedMerchants: number[] | string[] | [];
  fee: {
    deposit: number;
    depositFixed: number;
    fixedCurrency: string;
    depositFeeMin: number;
  },
  skipKyc: boolean;
  psp: boolean;
}
export interface IIvyBank {
  capabilities: string[]
  test: boolean,
  defaultBank: boolean,
  currencies: string[],
  market: string[],
  logo: string,
  name: string,
  id: string
}
export interface IIvyBank {
  capabilities: string[]
  test: boolean,
  defaultBank: boolean,
  currencies: string[],
  market: string[],
  logo: string,
  name: string,
  id: string
}

export function useApmList() {
  return useFetchApi(
    async (query: any) => Api.post('/apm/list', { limit: 100, ...query }),
    { items: [] }
  );
}

export function useIvyBanksList() {
  return useFetchApi(
    async (query: any) => Api.post('/apm/ivy/list', { limit: 50, ...query }),
    { items: [] }
  );
}

export function useCreateApm(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/apm/create', data),
    cb,
    'Method successfully created'
  );
}

export function useUpdateApm(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/apm/update', data),
    cb,
    'Method successfully updated'
  );
}

