import React from 'react';

import { Field } from 'react-final-form';

import { KeyboardDatePicker } from '@material-ui/pickers';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IDateFieldProps {
  name: string;
  label?: string;
  max?: Date | number;
  min?: Date | number;
  validate?: any;
  margin?: boolean;
  fullWidth?: boolean;
}

interface IStylesProps {
  margin?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: ({ margin }: IStylesProps) => ({
    marginTop: margin ? theme.spacing(2) : 'auto',
    '& .MuiInputAdornment-positionEnd': {
      marginRight: '-20px'
    }
  })
}));

const DateField = ({ name, label, min, max, validate, margin, fullWidth }: IDateFieldProps) => {
  const classes = useStyles({ margin });
  return (
    <Field
      name={name}
      render={({ input: { onChange, onBlur, value }, meta: { error, touched } }) => (
        <KeyboardDatePicker
          onChange={(date) => {
            onChange(date);
            onBlur();
          }}
          value={value || null}
          maxDate={max}
          minDate={min}
          format="yyyy-MM-dd"
          variant="inline"
          autoOk
          label={label}
          error={touched && !!error}
          helperText={touched && error}
          fullWidth={fullWidth}
          inputVariant="outlined"
          className={classes.root}
        />
      )}
      format={(v) => (v)}
      validate={validate}
    />
  );
};

export default DateField;
