import { Form } from 'react-final-form';

import { Button, makeStyles, Typography } from '@material-ui/core';

import ProviderSettingsForm from './ProviderSettingsForm';
import useFormStyles from '../../../../hooks/useFormStyles';
import { useGetProviderSettingsInitialValues } from './hooks';
import {  ProtectionInput } from '../../../../components/Form';
import { IProviderSettingsChangeFormProps } from '../../../../types';
import { mapAccountsByCurrency } from '../../../../helpers/providerSettings';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2.5),
  },
 }));

const ProviderSettingsChangeForm  = ({ 
  schema,
  update,
  updating,
  deleting,
  countries,
  provider,
  deleteSettings,
  ...props
}: IProviderSettingsChangeFormProps) => {

  const classes = useStyles();
  const formClasses = useFormStyles();

  const getInitialValues = useGetProviderSettingsInitialValues({ countries, provider });

  const initialValues = getInitialValues(provider, schema);

  const handleSubmitForm = (values: Record<string, any>) => {
    const { legalEntity,'2fa': authCode, merchantIDMain, ...rest } = values;

    if (rest?.accounts) {
      rest.accounts = mapAccountsByCurrency(rest.accounts);
    }

    const payload = {
      id: provider.id,
      '2fa': authCode,
      merchantId: merchantIDMain,
      legalEntity: legalEntity,
      provider: provider.provider,
      config: { ...rest, type: provider.provider }
    };

    update(payload);
  };

  return (
    <>
      <ProviderSettingsForm
        countries={countries}
        schemaId={schema?.id}
        initialValues={initialValues}
        properties={schema.properties}
        onSubmitForm={handleSubmitForm}
        isDisabledSubmitButton={deleting || updating}
        { ...props}
      />
      
      <Typography variant="h6" className={classes.title} color="primary">Delete provider settings</Typography>
      <Form
        onSubmit={(values) => deleteSettings({ id: provider.id, '2fa': values['2fa'] })}
        initialValues={{ '2fa': '' }}
        render={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <div className={formClasses.row}>
              <ProtectionInput margin="none" />
              <Button
                type="submit"
                size="large"
                disabled={deleting || updating || invalid}
                variant="contained"
              >
                Delete
              </Button>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default ProviderSettingsChangeForm;