type IAccount = { 
  currency: string
  id: string 
}

export const mapAccountsByCurrency = (accounts: IAccount[]): Record<string, string> => {
  return accounts.reduce((acc: Record<string, string>, item) => {
    acc[item.currency] = item.id;
    return acc;
  }, {});
};
