import { IKycCountry } from '../../../../../api/kyc';
import { IProviderSettings } from '../../../../../api/paymentGateway';

type IProviderSchema = Record<string, any>

interface IUseGetProviderSettingsInitialValuesProps {
  countries: IKycCountry[]
  provider: IProviderSettings
}

export const useGetProviderSettingsInitialValues = ({
  countries,
  provider
}: IUseGetProviderSettingsInitialValuesProps) => {

   function sortCountries(data: any) {
      return data.map((code: string) => countries
        .find((c: IKycCountry) => c.code === code))
        .filter((c: IKycCountry) => c !== undefined)
        .sort((a: IKycCountry, b: IKycCountry) => a.name.localeCompare(b.name))
        .map((c: IKycCountry) => c.code);
    }

    const getInitialValues = (providerData: IProviderSettings, schemaData: IProviderSchema) => {
      const initialValues = {} as IProviderSchema;
      const schemaProperties = schemaData.properties;

      Object.keys(schemaProperties).forEach((field) => {
          const fieldSchema = schemaProperties[field];
          const providerValue = providerData?.config[field];

          if (providerValue !== null) {
            if (field === 'accounts') {
              const mappedAccounts = Object.entries(providerValue).map(([currency, id]) => ({ currency, id }));
              initialValues[field] = mappedAccounts;
            } else {
              if (field === 'supportedLanguages') {
                const supportedLanguagesList= sortCountries(providerValue);
                initialValues[field] = supportedLanguagesList;
              } else {
                initialValues[field] = providerValue;
              }
            }
          } else {
              if (fieldSchema.type === 'array') {
                  initialValues[field] = [];
              } else if (fieldSchema.type === 'string') {
                  initialValues[field] = '';
              } else if (fieldSchema.type === 'number') {
                  initialValues[field] = 0;
              } else {
                  initialValues[field] = null;
              }
          }
      });

      return {
          ...initialValues,
         '2fa': '',
          legalEntity: provider?.legalEntity ?? '',
          merchantIDMain: provider?.merchantId ?? '',
        };
    };

   return getInitialValues;

};
