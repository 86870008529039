import Api, { useFetchApi, useUpdateApi } from './index';
import { IMerchantSettlementFees } from '../types';

export enum SettlementStatus {
  SETTLED = 'SETTLED',
  REQUESTED = 'REQUESTED',
  REJECTED = 'REJECTED'
}

type Operation = Record<string, number>

export type Operations = Record<number, Operation>

export interface SettlementCalculatedFees {
  customerFees: CustomerFees[];
}

export interface CustomerFees {
  customerId: number;
  currencyFeesList: CurrencyFees[];
}

export interface CurrencyFees {
  currencyCode: string;
  feeDetails: FeeDetails;
}

export interface FeeDetails {
  fiatSettlementFee?: number;
  cryptoSettlementFee?: number | null;
  fxSettlementFee?: number | null;
  additionalSettlementFee?: number | null;
}

export interface ISettlement{
  id: number;
  createdAt: number;
  updatedAt: number;
  status: SettlementStatus;
  customerId: number;
  settlementAmount: number;
  settlementCurrency?: string;
  legalEntity: string;
  requestedEurAmount: string;
  operations: Operations;
  calculatedFees?: SettlementCalculatedFees;
  settlementFees: Record<string, IMerchantSettlementFees & { additionalSettlementFee?: number }>;
  blockchainTxId?: string;
}

export interface ICustomerSettlementFees {
  customerId: number;
  settlementFees: IMerchantSettlementFees;
}

export interface SettlementFeeListRequest {
  customerIds: Set<number>;
}

export function useSettlementsList() {
  return useFetchApi(
    async (query: any) => Api.post('/settlement/find', { ...query, limit: 50 }),
      { items: [] }
  );
}

export function useCustomerSettlementFees() {
  return useFetchApi(
    async (request: SettlementFeeListRequest) => await Api.post('/settlement/customer-fees', request),
    { items: [] as ICustomerSettlementFees[] }
  );
}

export function useSettlementOne() {
  return useFetchApi(
    async (id: string) => Api.post('/settlement/find', { filter: { id }, limit: 1 }),
      { items: [] }
  );
}

export function useSettlementUpdate(cb: any) {
  return useUpdateApi(
     async (data: any) => Api.post('/settlement/update', data),
     cb,
     'Settlement successfully updated'
  );
}

export function useSettlementCreate(cb: any) {
  return useUpdateApi(
     async (data: any) => Api.post('/settlement/init', data),
     cb,
     'Settlement successfully created'
  );
}

interface IAdditionalSettlementFeeRequest {
  settlementId: number;
  additionalSettlementFee: number;
  '2fa': string;
}

export function useSettlementAdditionalFeeUpdate(cb: any) {
  return useUpdateApi(
     async (data: IAdditionalSettlementFeeRequest) => Api.post('/settlement/additional-fee', data),
     cb,
     'Additional fee saved'
  );
}


