import React from 'react';

import { Form } from 'react-final-form';

import { merge } from 'lodash';

import { useSnackbar } from 'notistack';

import { Button, makeStyles, Typography } from '@material-ui/core';

import { 
  ICustomer,
  useCustomerDetails,
  useCustomerFeesList,
  useCustomerFeeCreate,
  useCustomerFeeUpdate,
  useCustomerSettingsUpdate,
  useCustomerUpdateLegalEntity,
  DEFAULT_MERCHANT_NAMING_SETTINGS
} from '../../../../api/customers';
import { getInitialUI } from '../../../../api/merchant';
import useFormStyles from '../../../../hooks/useFormStyles';
import { getCopiedMerchantLabel } from '../../../../helpers';
import { WIIDGET_ENV } from '../../../../constants/environment';
import { merchantsForCopying } from '../../../../constants/merchantsForCopying';
import { AutocompleteFieldWithCreateOption, ProtectionInput } from '../../../../components/Form';

const useStyles = makeStyles((theme) => ({
   buttonWrapper: {
     marginTop: theme.spacing(2),
   },
   title: {
    marginBottom: theme.spacing(2)
   }
 }));

interface ICopyForm {
   loadCustomer?: (id: string)=> void;
   customer: ICustomer;
   customerFees: Record<string, any>;
}

function CopyForm({ loadCustomer, customer, customerFees }: ICopyForm) {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [{ data: сopiedСustomer }, loadingСopiedСustomer, loadСopiedСustomer ] = useCustomerDetails();
  const [{ items: сopiedCustomerFees }, loadingCopiedCustomerFees, loadCopiedCustomerFees] = useCustomerFeesList();

  const customerApmFees = customerFees.find((item: Record<string, any>) => item.customerId && !item.currency);

  const [createFees, creatingFees] = useCustomerFeeCreate(() => null);
  const [updateFees, updatingFees] = useCustomerFeeUpdate(() => null);
  const [updateCustomerEntity, updatingCustomerEntity] = useCustomerUpdateLegalEntity(() => null);

  const [updateSettings, updatingSettings] = useCustomerSettingsUpdate((res: any) => {
    if (res.success) {
      loadCustomer?.(customer.id);
    }
  }, 'Settings updated');

  const handleSubmit = (formData: Record<string, any>) => {
    const isMerchant = сopiedСustomer.authorities?.includes('ROLE_MERCHANT');

    const copiedCustomerApmFees =
      сopiedCustomerFees.find((item: Record<string, any>) => item.customerId && !item.currency);

    const isEmptySettingsOrApmFees = !сopiedСustomer?.settings || !isMerchant ||
      !copiedCustomerApmFees?.fees?.apmFee || Object.keys(copiedCustomerApmFees.fees.apmFee).length === 0;

    if (isEmptySettingsOrApmFees) {
      enqueueSnackbar('Empty apm fees or merchant settings', { variant: 'error' });
      return;
    }

    const { ui } = сopiedСustomer.settings ?? {};
    const initialUI = merge(getInitialUI(), ui);

    const settings = {
      customerId: String(customer.id),
      ...сopiedСustomer.settings,
      includedCurrencies: сopiedСustomer.includedCurrencies,
      fee: сopiedСustomer.fees,
      excludedCurrencies: сopiedСustomer.excludedCurrencies,
      smsesPerDay: сopiedСustomer.dailySmsLimit,
      merchantNaming: сopiedСustomer?.merchantNaming ?? DEFAULT_MERCHANT_NAMING_SETTINGS,
      successUrl: сopiedСustomer?.settings?.successUrl ?? '',
      failUrl: сopiedСustomer?.settings?.failUrl ?? '',
      settlementCryptoCurrency: сopiedСustomer?.settings?.settlementCryptoCurrency ?? null,
      ui: JSON.stringify(initialUI)
    };

    updateCustomerEntity({ '2fa': formData['2fa'], legalEntityId: сopiedСustomer?.legalEntity, id: customer.id });
    updateSettings(settings);

    if (!customerApmFees) {
      createFees({ '2fa': formData['2fa'], customerId: customer.id, fees: copiedCustomerApmFees?.fees ?? {} });
    } else {
      updateFees({ 
        customerId: customer.id, 
        id: customerApmFees.id, 
        '2fa': formData['2fa'], 
        fees: copiedCustomerApmFees?.fees ?? {} 
      });
    }
  };

  return (
    <>
      <Typography variant="body1" className={classes.title} color="primary">Copy Existing Merchant Settings</Typography>
      {!сopiedСustomer?.id &&
        <Form
          onSubmit={(values) => {
            loadСopiedСustomer(values.customerId);
            loadCopiedCustomerFees(values.customerId);
          }}
          initialValues={{ customerId: '' }}
          render={({ handleSubmit, invalid }) => {
            return (
              <form onSubmit={handleSubmit} className={formClasses.root}>
                <div className={formClasses.row}>
                  <AutocompleteFieldWithCreateOption
                    canCreateOption
                    name="customerId"
                    label="Customer ID"
                    optionsList={merchantsForCopying[WIIDGET_ENV as keyof typeof merchantsForCopying]
                      .map((item) => `${item.label} (${item.value})`)}
                  />
                  <div className={classes.buttonWrapper}>
                    <Button
                      type="submit"
                      disabled={
                        invalid || 
                        loadingСopiedСustomer || 
                        updatingSettings || 
                        creatingFees || 
                        updatingFees || 
                        loadingCopiedCustomerFees
                      }
                      size="large"
                      variant="contained"
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
      />}
      {сopiedСustomer?.id &&
        <Form
          onSubmit={(values) => handleSubmit(values)}
          initialValues={{ '2fa': '' }}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit} className={formClasses.root}>
              <div className={formClasses.row}>
                <ProtectionInput />
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    disabled={
                      invalid || 
                      loadingСopiedСustomer || 
                      updatingSettings || 
                      creatingFees || 
                      updatingFees || 
                      loadingCopiedCustomerFees ||
                      updatingCustomerEntity
                    }
                    size="large"
                    variant="contained"
                  >
                    Copy from {getCopiedMerchantLabel(сopiedСustomer.id)}
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
      }
    </>
  );
}

export default CopyForm;