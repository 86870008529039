import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import Loader from '../../../components/Loader';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import OrderList from '../../Orders/OrdersList';
import OrdersFilter from '../../Orders/OrdersFilter';
import ColHead from '../../../components/UI/ColHead';
import { useOrdersList } from '../../../api/order';
import useFilter from '../../../hooks/useFilter';
import useColumns, { IColumn } from '../../../hooks/useColumns';
import useGlobalStyles from '../../../components/Layout/useGlobalStyles';
import EmptyList from '../../../components/EmptyList';
import { Waypoint } from 'react-waypoint';
import { AbilityContext } from '../../../acl/can';

interface IProps {
	id: number
}

const defaultColumns: IColumn[] = [
	{ id: 'id', name: 'ID', active: true, component: <ColHead title="ID" key="id" /> },
	{ id: 'extId', name: 'Ext. ID', active: true, component: <ColHead title="Ext. ID" key="extId" /> },
	{ id: 'invoiceId', name: 'Invoice ID', active: true, component: <ColHead title="Invoice ID" key="invoiceId" /> },
	{ id: 'type', name: 'Type', active: true, component: <ColHead title="Type" key="type" /> },
	{ id: 'requested', name: 'Requested by', active: true, component: <ColHead title="Requested by" key="reqBy" /> },
	{ id: 'customer', name: 'Customer', active: false, component: <ColHead title="Customer" key="customer" /> },
	{ id: 'amount', name: 'Amount', active: true, component: <ColHead title="Amount" key="amount" /> },
	{
		id: 'paymentAmount',
		name: 'Payment amount',
		active: true,
		component: <ColHead title="Payment amount" key="pAmount" />
	},
	{
		id: 'paymentsCount',
		name: 'Payments count',
		active: true,
		component: <ColHead title="Payments count" key="pCount" />
	},
	{
		id: 'paymentMethod',
		name: 'Payment Method',
		active: true,
		component: <ColHead title="Payment Method" key="pMethod" />
	},
	{ id: 'status', name: 'Status', active: true, component: <ColHead title="Status" key="status" /> },
	{
		id: 'paymentFinished',
		name: 'Payment finished',
		active: false,
		component: <ColHead title="Payment finished" key="pFinished" />
	},
	{ id: 'convRate', name: 'Conversion rate', active: false, component: <ColHead title="Conversion rate" key="cRate" /> },
	{ id: 'convAt', name: 'Converted at', active: true, component: <ColHead title="Converted at" key="cAt" /> },
	{ id: 'created', name: 'Created at', active: true, component: <ColHead title="Created at" key="created" /> },
	{ id: 'expire', name: 'Will expire', active: false, component: <ColHead title="Will expire" key="expire" /> },
];

const emptyFilter = {
	orderId: undefined
};

export default function OrdersTab({ id }: IProps) {
	const history = useHistory();
	const classes = useGlobalStyles();
	const [{ items, cursor, full, error }, loading, load] = useOrdersList();
	const [setFilter, filter] = useFilter('customer_orders', emptyFilter);
	const [columns, ColumnsFilter] = useColumns('customer_orders', defaultColumns);

	const ability = useContext(AbilityContext);

	const cannot = ability.cannot('view', 'order-details');
	useEffect(() => {
		load({ cursor: 0, filter: { ...filter, acceptedBy: id.toString() } }, cannot);
	}, [filter, id, ability]);

	function setDetailsUrl(id?: string) {
		if (id) {
			history.push(`/orders/order/${id}`);
		} else {
			history.push('/orders');
		}
	}

  return (
    <Grid container spacing={3}>
      <OrdersFilter filter={filter} onChange={setFilter} type="popup" />
      <Grid item className={classes.table}>
          <CardActions>
            <Grid container justify-content="flex-end">
              <Grid item>
                <ColumnsFilter />
              </Grid>
            </Grid>
          </CardActions>
          {items.length > 0
            ? <OrderList items={items} columns={columns} onRowClick={(id: string)=>{setDetailsUrl(id);}} />
            : <EmptyList error={error} loading={loading} />
          }
          <Loader loading={loading} />
          {items.length > 0 && !loading && !error && !full && <Waypoint onEnter={() => load({ cursor, filter: { ...filter, acceptedBy: id.toString() } }, ability.cannot('view', 'order-details'))} />}
      </Grid>
    </Grid>
  );
}
