import { IOrderItem } from '../api/order';
import { ICustomer } from '../api/customers';
import { WIIDGET_ENV } from '../constants/environment';
import { merchantsForCopying } from '../constants/merchantsForCopying';


export function getAcceptedBy(o: IOrderItem) {
  if (o.acceptedByEmail && o.acceptedByPhone) {
    return `${o.acceptedByEmail} / ${o.acceptedByPhone}`;
  }
  return o.acceptedByPhone || o.acceptedByEmail || o.acceptedById;
}

export const getBillingAddressFormInitialValues = (customer: ICustomer) => {
  return {
    '2fa': '',
    id: customer.id,
    city: customer?.billingAddress?.city,
    address: customer?.billingAddress?.address,
    zipCode: customer?.billingAddress?.zipCode,
    country: customer?.billingAddress?.country,
    lastName: customer?.billingAddress?.lastName,
    firstName: customer?.billingAddress?.firstName,
    middleName: customer?.billingAddress?.middleName,
    dateOfBirth: customer?.billingAddress?.dateOfBirth,
    placeOfBirth: customer?.billingAddress?.placeOfBirth,
  };
};

export const getCopiedMerchantLabel = (customerId: string) => {
  return merchantsForCopying[
      WIIDGET_ENV as keyof typeof merchantsForCopying
    ].find((merchant) => merchant.value === String(customerId))?.label || customerId;
};