import Api, { useFetchApi, useUpdateApi } from './index';

export enum BlockchainTxStatus {
  NEW = 'NEW',
  ERROR = 'ERROR',
  DEFERRED = 'DEFERRED',
  MANUAL = 'MANUAL',
  SUCCESS = 'SUCCESS',
  EXECUTED = 'EXECUTED',
  PENDING = 'PENDING'
}

export enum BlockchainTxType {
  WITHDRAWAL = 'WITHDRAWAL',
  WITHDRAWAL_EXCHANGE = 'WITHDRAWAL_EXCHANGE'
}

export enum BlockchainTxProvider {
  DAM = 'DAM',
  COINS_PAID = 'COINS_PAID',
  OKEX = 'OKEX',
  KUCOIN = 'KUCOIN',
  INTERNAL_BTC = 'INTERNAL_BTC',
  BINANCE = 'BINANCE',
}

export interface IBlockchainTx {
  id: number;
  amount: number;
  fee: number;
  currency: string;
  address: string;
  txId: string;
  status: BlockchainTxStatus;
  orderId: number;
  customerId: number;
  customerEmail: string;
  provider: BlockchainTxProvider;
  createdAt: Date;
  updatedAt: Date;
  request?: string;
  response?: string;
  error?: string;
  ccw: boolean;
}

export function useBlockchainTxList() {
  return useFetchApi(
    async (query: any) => {
      const q: any = { filter: {} };
      Object.keys(query).forEach((k) => {
        if (k === 'filter') {
          Object.keys(query.filter).forEach((f) => {
            if (f === 'customer' && query.filter.customer) {
              if (/^\d+$/.test(query.filter.customer) && query.filter.customer.length <= 19) {
                q.filter.customerId = query.filter.customer;
              } else {
                q.filter.customerEmail = query.filter.customer;
              }
            } else {
              q.filter[f] = query.filter[f];
            }
          });
        } else {
          q[k] = query[k];
        }
      });
      return Api.post('/blockchain/list', { filter: {}, limit: 50, ...q });
    },
    { items: [] }
  );
}

export function useBlockchainTxByOrder() {
  return useFetchApi(
    async (orderId: any) => Api.post('/blockchain/list', { filter: { orderId }, limit: 1 }),
    { items: [] }
  );
}

export function useBlockchainTxOne() {
  return useFetchApi(
    async (id: string) => Api.post('/blockchain/one', { id }),
    { data: {} }
  );
}

export function useSendBTC(cb: any) {
  return useUpdateApi(
    async (query: any) => Api.post('/blockchain/release', query),
    cb,
    'Successfully sending BTC to merchant'
  );
}

export function useBlockchainReinitTx(cb: any) {
  return useUpdateApi(
    async (query: any) => Api.post('blockchain/reinit', query),
    cb,
    'Successfully sending reinit request to merchant'
  );
}
