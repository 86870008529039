import {Operations, SettlementStatus} from '../api/settlements';

export function getSettlementStatusType(status: SettlementStatus) {
   switch (status) {
     case SettlementStatus.SETTLED:
       return 'success';
     case SettlementStatus.REQUESTED:
       return 'warning';
     case SettlementStatus.REJECTED:
       return 'danger';
     default:
       return;
   }
 }

export const sumOperationsByCurrency = (operations: Operations) => {
  if (Object.keys(operations).length === 0) {
    return [];
  }

  const currencyTotals = Object.values(operations)
    .reduce((acc, operation) => {
      Object.entries(operation).forEach(([currency, amount]) => {
        acc[currency] = (acc[currency] || 0) + amount;
      });
      return acc;
  }, {});

  return Object.entries(currencyTotals).map(([currency, amount]) => ({
    currency,
    amount
  }));
};